
.action-button {
    margin: 0px 0px 0px 0px;
    min-width: 100px !important;
    height: 38px;
}

.mat-button, .delete-mat-button {
    margin: 0px 0px 0px 0px;
    min-width: 100px !important;
    height: 38px;
}
