
.taskDialog {
    width: 512px;
}

.taskDialogRowContainer {
    display: flex;
    width: 100%;
    margin-bottom: 16px;
}

.taskDialogLongInputField {
    width: 100%;
}

.taskDialogInputField {
    flex-basis: 0;
    flex-grow: 1;
}

.taskDialogSubsequenceField {
    margin-left: 16px;
}

.taskInfoCard {
    padding: 0 16px 16px 16px;
    margin-right: 16px;
  /*  height of the panel is 100vh - height of (top nav bar , padding , location row , padding and bottom padding)*/
    height: calc(100vh - 3.5rem - 108px);
    flex-grow: 1;
}

.taskGalleryCard {
    padding: 0 16px 16px 16px;
    /*  height of the panel is 100vh - height of (top nav bar , padding , location row , padding and bottom padding)*/
    height: calc(100vh - 3.5rem - 108px);
    flex-grow: 1.5;
}

.taskTitleContainer {
    display: flex;
    margin-bottom: 16px;
}

.taskColorBar {
    width: 8px;
    height: auto;
    margin-right: 8px;
    border-radius: 3px;
}

.taskSmallChip {
    background-color: #222222;
    font-size: 12px;
    font-weight: normal;
    color: #E8E8E8;
    border-radius: 12px;
    height: 16px;
    padding: 1px 8px;
    margin-left: 8px;
    vertical-align: middle;
}

.taskRowContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.taskHeading2Container {
    align-items: center;
    height: 48px;
}

.taskContentRow {
    margin-bottom: 8px;
}

.taskLabelContainer {
    flex-basis: 169px;
    flex-grow: 1;
}

.taskButtonContainer {
    flex-grow: 1;
    align-items: flex-end;
}

.taskContentContainer {
    flex-basis: 169px;
    flex-grow: 1;
}

.taskGalleryRowContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.taskGalleryPhoto {
    min-width: 510px;
    min-height: 584px;
    flex-grow: 1;
    border-radius: 8px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    max-height: 700px;
    max-width: 1100px;
}

.taskThumbnailList {
    min-width: 81px;
    height: 584px;
    display: flex;
    flex-flow: column;
    align-items: center;
    margin-left: 16px;
    overflow-y: auto;
}

.taskThumbnail {
    width: 56px;
    height: 56px;
    flex-shrink: 0;
    margin-bottom: 8px;
    border-radius: 8px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}

.taskThumbnailSelected {
    width: 64px;
    height: 64px;
    flex-shrink: 0;
    margin-bottom: 8px;
    border-radius: 8px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.taskHeading {
    font-weight: bold;
    font-size: 24px;
}

.taskSubHeading {
    font-size: 16px;
    font-weight: normal;
    color: #626262;
}

.taskHeading2 {
    font-size: 16px;
    font-weight: bold;
    color: #626262;
}

.taskTable td {
    vertical-align: middle;
    cursor: pointer;
}

.taskTableLocation {
    font-size: 14px;
    font-weight: bold;
    color: #626262;
}

.taskTableContent {
    font-size: 14px;
    font-weight: normal;
    color: #626262;
}
