@media (min-width: 592px) 
{
    .mdc-dialog .mdc-dialog__surface {
        max-width: none !important;
    }
}

/* Remove weird padding on toasts at smaller sizes */
@media (max-width: 768px) and (min-width: 481px) {
    .mat-toast-container > div {
        padding: 0px;
    }
}

@media (max-width: 480px) and (min-width: 241px) {
    .mat-toast-container > div {
        padding: 0px;
    }
}

@media (max-width: 240px) {
    .mat-toast-container > div {
        padding: 0px;
    }
}

.btn-secondary {
    background-color: transparent;
    border-left: none;
    width: 56px;
}

    .btn-secondary:hover {
        background-color: var(--d-blue-faded);
    }

/*NumericUpandDown Overides form padding and alignment*/
.mat-numericUpDownField input {
    padding: 12px 16px !important;
    text-align: left !important;
}

.mat-toast-container > div {
    opacity: 1;
}

.mat-toast-container {
    bottom: 50vh;
}

.mat-toast-body {
    padding-right: 16px;
}

/* when we put a dialog inside a table cell with right align, this fixes that */
.mdc-dialog {
    text-align:left;
}

.mdc-button--raised.action-button.mdc-button {
    color: white;
}
.mdc-button--outlined.action-button.mdc-button {
    color: var(--d-blue);
}

/* floating label colour*/
.mdc-select:not(.mdc-select--disabled).mdc-select--focused .mdc-floating-label {
    /*Don't change colour*/
    color: inherit;
}

.mdc-text-field--focused:not(.mdc-text-field--disabled).mdc-floating-label.mdc-floating-label--float-above {
    color: black;
}

/* Override styling to work-around bug similar to https://github.com/SamProf/MatBlazor/issues/444 which has not been resolved 
    This class effects the highlighted area on a MatTextField which has the Id autofillable only*/
.mdc-text-field__input#autofillable {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding-top: 16px;
    padding-left: 16px;
    position: absolute;
}

/* Override styling to work-around bug similar to https://github.com/SamProf/MatBlazor/issues/444 which has not been resolved 
    This class effects the floating label on the MatTextField after it has been autofill. Height: 60% was used because that is what
    the MatTextField sets the height on the label to when it's working corretly.*/

.mdc-text-field__input:-webkit-autofill+.mdc-floating-label{
    height: 60%;
}

.e-btn.e-flat.e-primary, .e-css.e-btn.e-flat.e-primary, .e-btn.e-flat.e-primary:active, .e-btn.e-flat.e-primary.e-active, .e-css.e-btn.e-flat.e-primary:active, .e-css.e-btn.e-flat.e-primary.e-active, .e-btn.e-flat.e-primary:focus, .e-css.e-btn.e-flat.e-primary:focus {
    color: var(--d-blue);
    background-color: transparent;
}

.e-btn.e-flat.e-primary:hover:not(:disabled), .e-css.e-btn.e-flat.e-primary:hover:not(:disabled) {
    color: var(--d-blue);
    background-color: #0000000f;
}

.e-upload .e-upload-files .e-upload-file-list .e-file-container .e-progress-inner-wrap .e-upload-progress-bar.e-upload-progress {
    background: var(--d-blue);
}
/* style for syncfusion drop down list, this controls the style for the selected value not the dropdown list itself */
.e-ddl.e-input-group.e-control-wrapper .e-input {
    font-size: 16px;
    height:52px;
}
/* style for the syncfisopm drop down list, this controls the style the the dropdown list itself not the selected value */
.e-dropdownbase .e-list-item, .e-dropdownbase .e-list-item.e-item-focus {
    font-size: 16px;
    min-height: 18px;
}

.e-multiselect .e-delim-values, .e-multi-select-wrapper .e-delim-values .e-remain {
    display: none;
}

.e-multi-select-wrapper .e-chips-collection {
    display: block !important;
}


.ejs-multi-select {
    border: 1px solid red;
    border-radius: 3px;
}

.mdc-dialog__title {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 24px;
    font-weight: bold;
    color: #494949 !important;
    padding-left: 16px;
}
/* There is a bug with the MatSelect component where sometimes the selected-text::before content property is
    set to "â€<". This causes those characters to be displayed in the picker. Setting this override
    value prevents the characters being inserted.*/
.mdc-select--outlined .mdc-select__anchor .mdc-select__selected-text::before{
    content: "";
}
/* By default we are getting 0.5rem for label margin-botton so reset to 0px */
label {
    margin-bottom: 0px
}
/* Dev Express uploader, progress bar. This padding stops the close button being cut off */
.pr-0 {
    padding-right: 16px !important;
}