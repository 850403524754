#index-logo {
    padding: 30px 0px;
    width: 30vw;
    height: auto;
    display: flex;
    margin: 30px auto;
}

.dashboard-container {
    display:flex;
    flex-flow: row wrap;
    justify-content: stretch;
    margin: auto;
}


.nowidthcard {
    display:flex;
    padding: 16px;
    margin: 16px;
   
    flex-grow: 1;
    flex-basis: 0;
}
.card {
    display: flex;
    padding: 16px;
    margin: 16px;
    min-width: 40%;
    flex-grow: 1;
    flex-basis: 0;
}

@media (max-width: 1000px) {
    .card {
        flex-basis: auto;
    }
}

@media (max-width: 767.98px) {
    #index-logo {
        width: 65vw;
    }
}

.helper-text {
    font-size: 12px;
}

.info-text {
    font-size: 12px;
    color: #676767;
}

.version-number {
    font-family: 'Open Sans';
    font-size: 12px;
    padding-top: 4px;
    padding-left: 12px;
    color: white;
}

.nav-item-group {
    position: relative;
}

.subMenu {
    visibility: hidden;
    position: absolute;
    left: 100%;
    display: block;
    border: 1px solid #e0e0e0;
    min-width: 200px;
    min-height: 128px;
    background-color: white;
    padding: 0px 16px;
    top: 0px;
}

.nav-item-group:hover .subMenu, .subMenu:hover {
    visibility: visible;
}

.subMenu ul {
    list-style-type: none;
    padding-inline-start: 0px;
}
.subMenu ul .nav-item {
    padding-bottom: 0px;
}

.subMenu .nav-link {
    padding-left: 0px;
}