@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: local('☺'),
        url(../../fonts/Roboto_400_normal.woff2) format('woff2'), /* http://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu4mxK.woff2 */
        url(../../fonts/Roboto_400_normal.woff) format('woff'), /* http://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu4mxM.woff */
        url(../../fonts/Roboto_400_normal.ttf) format('truetype'), /* http://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu4mxPKTU1Kg.ttf */
}
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: local('☺'),
        url(../../fonts/Roboto_500_normal.woff2) format('woff2'), /* http://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmEU9fBBc4.woff2 */
        url(../../fonts/Roboto_500_normal.woff) format('woff'), /* http://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmEU9fBBc-.woff */
        url(../../fonts/Roboto_500_normal.ttf) format('truetype'), /* http://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmEU9fBBc9AMP6lQ.ttf */
}
