@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(../../fonts/Material_Icons_400_normal.eot); /* http://fonts.gstatic.com/l/font?kit=flUhRq6tzZclQEJ-Vdg-IuiaDsNY&skey=4fbfe4498ec2960a&v=v141 */
    src: local('☺'),
        url(../../fonts/Material_Icons_400_normal.eot?#iefix) format('embedded-opentype'), /* http://fonts.gstatic.com/l/font?kit=flUhRq6tzZclQEJ-Vdg-IuiaDsNY&skey=4fbfe4498ec2960a&v=v141 */
        url(../../fonts/Material_Icons_400_normal.woff2) format('woff2'), /* http://fonts.gstatic.com/s/materialicons/v141/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2 */
        url(../../fonts/Material_Icons_400_normal.woff) format('woff'), /* http://fonts.gstatic.com/s/materialicons/v141/flUhRq6tzZclQEJ-Vdg-IuiaDsNa.woff */
        url(../../fonts/Material_Icons_400_normal.ttf) format('truetype'), /* http://fonts.gstatic.com/s/materialicons/v141/flUhRq6tzZclQEJ-Vdg-IuiaDsNZIhQ8tQ.ttf */
        url(../../fonts/Material_Icons_400_normal.svg#Material+Icons_400_normal) format('svg'); /* http://fonts.gstatic.com/l/font?kit=flUhRq6tzZclQEJ-Vdg-IuiaDsNb&skey=4fbfe4498ec2960a&v=v141#MaterialIcons */
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
}