@font-face {
  font-family: 'icomoon';
  src:  url('@/fonts/icomoon.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"], .icomoon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-DistributionBoard:before {
  content: "\e902";
}
.icon-JumboBox:before {
  content: "\e903";
}
.icon-LightingBox:before {
  content: "\e904";
}
.icon-Magerzine:before {
  content: "\e905";
}
.icon-Pump:before {
  content: "\e906";
}
.icon-StarterBox:before {
  content: "\e907";
}
.icon-AirCompressor:before {
  content: "\e908";
}
.icon-SubStation:before {
  content: "\e909";
}
.icon-RefugeeChamber1:before {
  content: "\e90a";
}
.icon-FreshAirWay:before {
  content: "\e90b";
}
.icon-FreshAirBase:before {
  content: "\e90c";
}
.icon-ReturnAirWay:before {
  content: "\e90d";
}
.icon-Muster:before {
  content: "\e90e";
}
.icon-RefugeeChamber:before {
  content: "\e90f";
}
.icon-FirstAid:before {
  content: "\e900";
}
.icon-SurveyStation:before {
  content: "\e901";
}
