.main-layout-dropdown-toggler, .help-button {
    display: inline-flex;
    align-items: center;
    background: transparent;
    border: none;
    font-weight: bold;
    color: white;
    text-transform: uppercase;
    font-family: "Open Sans Condensed",sans-serif;
    font-weight: 700;
    font-size: 14px;
    column-gap: 8px;
}


.main-layout-dropdown-arrow {
    font-size: 10px;
    padding-left: 10px;
    
}

    .main-layout-dropdown-toggler:active, .main-layout-dropdown-toggler:focus {
        outline: none;
    }

.main-layout-dropdown-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 3.5rem;
    right: 0;
    width: 350px;
    padding: 30px 30px 10px 30px;
    text-align: center;
    background: #ffffff;
    /*box-shadow style source: MatBlazor mat-elevation-z3*/
    box-shadow:
        0px 2px 4px -1px rgba(0,0,0,0.2),
        0px 4px 5px 0px rgba(0,0,0,0.14),
        0px 1px 10px 0px rgba(0,0,0,0.12);
    border-radius: 2%;
}

.main-layout-profile-container {
    margin: 20px auto;
    width: 90px;
    height: 90px;
    font-size: 40px;
    text-align: center;
    line-height: 90px;
    color: #ffffff;
    background: var(--d-blue);
    border: transparent;
    border-radius: 50%;
}