@import url('open-iconic/font/css/open-iconic-bootstrap.min.css');

@font-face {
    font-family: 'icomoon';
    src: url('@/fonts/icomoon.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    src: url(@/fonts/OpenSans-Regular.ttf)
}


@font-face {
    font-family: 'Open Sans Condensed';
    font-style: normal;
    font-weight: 700;
    src: url(@/fonts/OpenSansCondensed-Bold.ttf)
}

:root {
    --navbar-colour: #1f282c;
    --sidebar-colour: #f5f5f5;
    --d-red: #CE5252;
    --d-yellow: #FFC000;
    --d-yellow-faded: #FFC00026;
    --d-green: #00B050;
    --d-green-faded: #00B05026;
    --d-blue: #3D9BEA;
    --d-blue-faded: #3D9BEA26;
    --light-component-background: #E8E8E8;
    --primary-text: #E8E8E8;
    --light-primary-text: #494949;
    --light-secondary-text: #676767;
    --light-primary-text-faded: #49494926;
    --light-brandcolour: #3D9BEA;
    --grey-1: #E6E6E6;
    --panel-level-1: #ffffff;
    --light-grey: #dee2e6;
    /** text sizes **/
    --font-size-heading: 20px;
    --font-size-body: 14px;
    --standard-border-radius: 3px;
    --input-field-height: 42px;
    --thin-border-thickness: 1px;
}

.icon {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    font-size: var(--font-size-heading);
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.top-bar-logo {
    color: white;
    display: inline-flex;
    align-items: center;
    background: transparent;
    border: none;
    padding: 0px;
    font-family: "Open Sans Condensed",sans-serif;
    font-weight: 700;
    font-size: var(--font-size-heading);
    padding-left: 8px;
}

    .top-bar-logo img {
        padding-right: 1rem;
    }

    .top-bar-logo span {
        position: relative;
    }

.top-row.navbar.navbar-light {
    padding: 0.5rem 0.8rem;
}

html, body {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.hover-highlight {
    color: inherit; /* Keep the original color */
    text-decoration: none; /* Remove underline */
}

.hover-highlight:hover {
    color: #3d9bea !important; /* Change text color to blue on hover */
    
}

a {
    color: #0366d6;
}


th.brand-blue {
    color: var(--d-blue);
}

/* override style for mat blazor card box shadow */
.mdc-card {
    box-shadow: 0px 1px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0,0,0,.12);
}

/* Sets the label color of the bugged input fields. MatBlazor*/
.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
    color: #000000;
}

.mdc-select:not(.mdc-select--disabled).mdc-select--focused .mdc-floating-label {
    color: #000000;
}

/* Hide numeric up down arrows */
.mdc-text-field--with-trailing-icon .mdc-text-field__icon {
    visibility: hidden;
}

app {
    position: relative;
    display: flex;
    flex-direction: column;
}

.halfGauge svg {
    height: 240px;
}

.halfGauge {
    height: 240px;
    margin-top: -160px;
}

p {
    margin: 0px;
}

.mainContainer {
    width: 100%;
}

.fullWidth {
    width: 100%;
}

.halfWidth {
    width: 50%;
}

.status-chip {
    min-width: 92px;
    width: fit-content;
    border-radius: 2px;
    border: 0px;
    display: flex;
    padding-left: 4px;
    padding-right: 4px;
}

.truncate-two-lines {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 17px; 
    max-height: 3em;
}

.status-chip-text {
    margin-left: auto;
    margin-right: auto;
    text-transform: uppercase;
    color: white;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 12px;
    font-weight: bold;
}

.action-icon {
    /* must be !important otherwise dev express overrides these values when the button is disabled */
    background-color: transparent !important;
    color: black !important;
    border: none;
    font-size: 18px;
    border-radius: 50%;
    width: 36px;
    height: 36px;
}

.file-download-background {
    background-color: lightgrey !important;
}

.action-icon:hover {
    /* must be !important otherwise dev express overrides these values when the button is disabled */
    background-color: transparent !important;
    color: black !important;
}

.success-color {
    background-color: var(--d-green);
}

.entryField {
    width: 230px
}

.longEntryField {
    width: 480px
}

.input-field-floating-label {
    border-radius: 4px;
    border-width: 1px;
    border-color: rgba(0,0,0,.5);
    padding-left: 16px;
    border-style: solid;
}

    .input-field-floating-label:focus {
        outline: none;
        border-color: rgb(61, 155, 234);
        border-width: 2px;
        border-style: solid;
        padding-left: 16px;
    }

.floating-label {
    position: relative;
    right: 95%;
}

.input-field-floating-label:focus + .floating-label, .input-field-floating-label:not(:placeholder-shown) + .floating-label {
    position: relative;
    right: calc(95% + 16px);
    transform: translateY(-126%) scale(0.75);
    background-color: white;
    padding-left: 8px;
    padding-right: 8px;
    color: black;
    transition: all .15s;
}

.subsequenceItem {
    margin-left: 20px;
}

/* used for styling the DxButton which doesn't accept a style param, only a class */
.small-right-margin {
    margin-right: 8px;
}

.input-field-height {
    height: var(--input-field-height);
}

.warningMessage {
    color: orange;
    font-size: small;
    overflow-wrap: break-word;
    line-height: 1.2;
}

.warning-level-1 {
    background-color: var(--d-red) !important;
}

.warning-level-2 {
    background-color: var(--d-yellow) !important;
}


.warning-level-1-icon {
    color: var(--d-red) !important;
}

.warning-level-2-icon {
    color: var(--d-yellow) !important;
}

.dash-survey {
    background-color: #7efc00 !important;
}


.errorMessage {
    font-size: small;
    color: red;
    overflow-wrap: break-word;
    line-height: 1.2;
}

.rowContainer {
    width: 100%;
    padding: 4px 8px 16px 0px;
    display: flex;
    align-items: center;
    /*flex-flow: row wrap;*/
}

.align-top {
    align-items: flex-start;
}

.width100 {
    width: 100%;
}

.container50 {
    width: 50%;
    padding: 8px;
}

.container45 {
    width: 45%;
    padding: 8px;
}

.container40 {
    width: 40%;
    padding: 8px;
}

.container20 {
    width: 20%;
    padding-right: 16px;
}

.container10 {
    width: 10%;
    padding-right: 16px;
}

.container5 {
    width: 5%;
    padding-right: 16px;
}

.textcenter {
    text-align: center;
}

.font-weight-normal {
    font-weight: normal;
}

.font-weight-normal-download {
    font-weight: normal;
    width: fit-content;
    text-align: end;
}

/* Container whose content deliberately overlaps each other */
.overlapping-container {
    display: grid;
    grid-template-areas: 'content';
    align-content: center;
}
/* content which sits behind other content */
.background-content {
    grid-area: content;
    z-index: 0;
    background-color: transparent;
}
/* content which sits in front of other content but allows mouse events to pass through to content behind */
.foreground-content {
    grid-area: content;
    pointer-events: none;
    z-index: 1;
    background-color: white;
    width: 100%;
    height: 100%;
    padding-left: 16px;
}

.select-no-caret {
    -webkit-appearance: none;
    -moz-appearance: none;
}

.input-transparent {
    pointer-events: none;
}

.circle {
    /*width: 20px;*/
    height: 20px;
    min-width: 20px;
    padding: 0px 4px;
    border-radius: 10px;
    background-color: var(--d-blue);
    text-align: center;
    float: left;
}

.circle-text {
    display: inline-block;
    color: white;
    margin: 0px auto 2px auto;
}

.circle .circle-icon-size {
    font-size: 15px;
    color: white;
    margin: 2px auto;
}

.panel-title-text {
    margin: 0px 0px 0px 50px;
    font-weight: bolder
}


.top-row {
    background-color: var(--navbar-colour);
    height: 3.5rem;
    display: flex;
    align-items: center;
    position: relative;
    border-bottom: 1px solid #d6d5d5;
    flex-flow: row nowrap;
}

.main {
    flex: 1;
    z-index: 1;
}

    .main .top-row {
        border-bottom: 1px solid #d6d5d5;
        justify-content: flex-end;
        z-index: 5;
    }

        .main .top-row > a {
            margin-left: 1.5rem;
            font-family: "Open Sans Condensed",sans-serif;
            font-weight: 700;
            text-transform: uppercase;
            font-size: var(--font-size-body);
        }

            .main .top-row > a:active {
                color: #3d9bea !important;
                background-color: #FFFFFF !important;
            }

.sidebar {
    /*background-image: linear-gradient(180deg, rgb(5, 39, 103) 0%, #3a0647 70%);*/
    background-color: var(--sidebar-colour);
    z-index: 1;
}
    /*big brain*/
    .sidebar:hover {
        z-index: 2;
    }

    .sidebar .top-row {
        justify-content: flex-start;
    }

    .sidebar .navbar-icon {
        height: 30px;
        margin: 0px 10px 0px 6px;
    }

    .sidebar .navbar-logo {
        height: 20px;
    }

    .sidebar .oi {
        width: 2rem;
        font-size: 1.1rem;
        vertical-align: text-top;
        top: -2px;
    }

.nav-item {
    font-size: 0.9rem;
}

    /*    .nav-item:first-of-type {
        padding-top: 1rem;
    }*/

    .nav-item a {
        color: #494949;
        border-radius: 0px;
        font-family: "Open Sans Condensed",sans-serif;
        font-weight: 700;
        text-transform: uppercase;
        font-size: var(--font-size-body);
        height: 3rem;
        display: flex;
        align-items: center;
        line-height: 3rem;
    }

        .nav-item a.active {
            color: var(--d-blue);
            background-color: #FFFFFF;
        }

        .nav-item:hover, .nav-item a:hover, .nav-item:focus, .nav-item a:focus {
            background-color: white;
            color: var(--d-blue);
        }

.collapsable {
    border-right: 1px solid #e2e2e2;
}

.navbar-toggler {
    /*background-color: rgba(255, 255, 255, 0.25);*/
    margin-right: 20px;
    border: transparent;
}

    .navbar-toggler:active, .navbar-toggler:focus {
        outline: none;
    }

.valid.modified:not([type=checkbox]) .valid.modified:not([type=select]) {
    outline: 1px solid #26b050;
}

.invalid {
    outline: 1px solid red;
}

.validation-message {
    color: red;
}

.horizontal-data-container-centered {
    text-align: center;
    width: 100%;
}

.horizontal-data-container {
    display: inline-block;
    width: 100%;
}

.horizontal-data-container-column {
    display: inline-block;
    width: 33%;
}

.horizontal-data-container-heading {
    display: block;
}

.horizontal-data-container-data {
    display: block;
    font-weight: bold;
}

.date-selector-container {
    display: flex;
    width: 100%;
    padding-left: 16px;
    margin-top: 8px;
}
/* used for styling the text field on the Dev Express Time Edtior */
.form-control-sm {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 16px;
    padding-left: 16px;
    height: 56px;
}

.single-small-button-container {
    width: 42px !important;
}

.double-small-button-container {
    width: 84px !important;
    display: inline-flex;
}

.vertical-table-title-section {
    display: flex;
    vertical-align: middle;
    width: 100%;
}

.vertical-table-title {
    display: inline-block;
    font-weight: bold;
    width: 100%;
    padding-top: 8px;
}

.vertical-table-row {
    padding-top: 16px;
    padding-bottom: 16px;
    height: 52px;
}

.vertical-table-row, last {
    border-bottom: 1px solid var(--light-grey);
}

.vertical-table-header-column {
    font-weight: normal;
    width: 15%;
}

.vertical-table-bold-text {
    font-weight: bold;
}

.article-table {
    border-collapse: collapse;
    border-spacing: 0;
    display: inline-block;
    overflow-x: auto;
    margin: 8px 0 32px;
    max-width: 100%;
    border: 1px solid #eee;
    width: 100%
}


    .article-table th {
        font-family: Roboto, sans-serif;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-size: 1rem;
        line-height: 1.75rem;
        font-weight: 400;
        letter-spacing: .04em;
        text-decoration: inherit;
        text-transform: inherit;
        background: whitesmoke;
        border-top: none;
        font-size: 1.143rem;
        color: #202124;
        text-align: left;
        border: 1px solid #eee;
        padding: 12px 12px 12px 24px;
    }

    .article-table td {
        border: 1px solid #eee;
        padding: 12px 12px 12px 24px;
    }

sub

.heading-inline {
    display: inline;
}

.mat-textfield {
    margin: 8px 4px 8px 0px
}


.block-container {
    margin-top: 30px;
}

.inline-tab-selector-container {
    display: inline-block;
    margin-right: 12px;
}

.inline-tab-selector-text {
    display: inline-block;
    padding-top: 16px;
    padding-bottom: 16px;
}

.tab-selector-rounded-rectangle {
    height: 8px;
    max-height: 8px;
    width: auto;
    background-color: var(--light-brandcolour);
    border-radius: 4px;
}

.tab-selector-line {
    margin-top: -4.5px;
    margin-right: -16px;
    margin-left: -16px;
}

.tab-selector-circle {
    display: inline-block;
    height: 24px;
    width: 24px;
    background-color: var(--light-brandcolour);
    border-radius: 12px;
}

.no-footer {
    margin-bottom: -200px;
}

@media (max-width: 767.98px) {
    .main .top-row {
        position: absolute;
        top: 0;
        right: 0;
        background: none;
    }

    .help-icon {
        position: fixed;
        /*top = screen-height - top-row-height - margin-bottom*/
        top: calc(100vh - 3.5rem - 1.5rem);
        right: 0;
        margin: 0rem 1.5rem 1.5rem 0rem;
        font-size: var(--font-size-heading);
        z-index: 1;
    }

    .navbar-icon {
        display: none;
    }

    .navbar-logo {
        display: none;
    }
}

@media (min-width: 768px) {
    app {
        flex-direction: row;
    }

    .sidebar {
        min-width: 250px;
        height: 100vh;
        position: sticky;
        top: 0;
    }

    .main .top-row {
        position: sticky;
        top: 0;
    }

    .navbar-toggler {
        display: none;
    }

    .sidebar .collapse {
        /* Never collapse the sidebar for wide screens */
        display: block;
    }

    .help-icon {
        display: none;
    }
}

.content {
    padding-left: 2rem !important;
    padding-right: 1.5rem !important;
    padding-top: 1.1rem;
    padding-bottom: 1.1rem;
}

.modal {
    display: block;
    position: fixed; /* Stay in place */
    z-index: 99999; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/*.modal-white-background {
    background-color: #ffffffee;
    display: flex;
    z-index: 98;
}*/

.modal-content {
    margin: 60px auto; /* 15% from the top and centered */
    padding: 0px;
    min-width: 300px; /* Could be more or less, depending on screen size */
    border: 0px;
}

.standard-dialog {
    min-width: 540px;
    min-height: 200px;
    width: 75%;
    font-size: var(--font-size-body);
}

.standard-dialog-header {
    font-family: Helvetica;
    font-size: var(--font-size-heading);
    background-color: white;
    margin-left: 20px;
    margin-top: 25px;
    border-bottom: 1px;
    border-color: black;
    display: flex;
}

.standard-dialog-body {
    margin-left: 6px;
}


.standard-dialog-footer {
    display: flex;
    font-family: Helvetica;
    font-size: var(--font-size-heading);
    background-color: white;
    margin-right: 20px;
    margin-left: 20px;
    margin-bottom: 20px;
}

.modal-header {
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1rem 1rem;
    border: none;
}

.modal-dialog, centered {
    align-self: center;
}

.small-modal {
    margin: auto;
    display: flex;
    position: relative;
    width: 50%;
    height: 33vh;
    min-height: 220px;
    min-width: 300px;
    top: 33%;
}

.small-modal-header {
    display: flex;
    margin: 16px 0px 0px 16px;
}

.modal-footer {
    margin-top: 20px;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1rem 1rem;
    border: none;
}

.cardHeader {
    border-radius: 4px 4px 0px 0px;
    border: none;
    width: 100%;
    background-color: black;
    font-size: large;
    padding: 8px 12px;
    color: var(--d-blue);
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cardHeaderContent {
    float: right;
}

.mainParentContainer {
    display: flex;
    flex-flow: column;
}

/*Help Text Styling*/

.helpText h1 {
    font-size: 36px;
    line-height: 40px;
    color: #383838;
}

.helpText h2 {
    font-size: 30px;
    line-height: 40px;
    margin-left: 4px;
    color: var(--d-blue);
}

.helpText h3 {
    font-size: 22px;
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 4px;
    color: #383838;
}

.helpText h4 {
    font-size: 16px;
    /*font-weight: bold;*/
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 4px;
    color: #383838;
}

.helpText p {
    font-size: 12px;
    line-height: 16px;
    margin-left: 10px;
}

.helpText h5 {
    font-size: var(--font-size-body);
    line-height: 20px;
    color: #d6d5d5;
    text-align: center;
}

.pickr {
    border: 1px solid #f1f1f1;
    border-radius: 4px;
}

.pickerLabel {
    padding: 4px;
}

.colourPreview {
    height: 32px;
    width: 32px;
    border: 1px solid #f1f1f1;
    border-radius: 4px;
}

.mat-textfield-readonly * {
    border-bottom-color: rgba(0, 0, 0, 0.42) !important;
    color: rgba(0, 0, 0, 0.42) !important;
}

/*.dash-has-survey {
    color: var(--d-blue);
    position: absolute;
    bottom: 2px;
    left: 50%;
    transform: translateX(-50%)
}*/

.centred-column {
    text-align: center;
}

.right-column {
    text-align: end;
}

.compact {
    padding: 0px;
    font-size: small
}

.hidden {
    visibility: hidden;
    display: none;
}

.hidden-column {
    visibility: collapse;
    display: none;
}

.last-column, .cuts-column {
    border-right: 1px solid var(--light-grey);
}

.left-column, .cuts-column {
    border-right: 1px solid var(--light-grey);
    border-left: 1px solid var(--light-grey);
}

table .total-cell {
    padding: 0px 4px;
    position: relative
}

    table .total-cell:before {
        content: "";
        display: block;
        height: 1px;
        overflow: hidden;
        background: black;
        position: absolute;
        top: 0;
        right: 2px;
        left: 2px;
    }

.offsetTableStickyHead thead {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: white;
    border: none;
    outline-offset: -1px;
    outline: var(--thin-border-thickness) solid var(--light-grey);
}

.table-header-text-small {
    color: var(--light-secondary-text);
    font-weight: normal;
    font-size: var(--font-size-body);
}

.tableStickyHead th {
    background-color: white;
    box-shadow: inset 0 1px 0 var(--light-grey), inset 0 -1px 0 var(--light-grey);
    border-top: none !important;
    border-bottom: none !important;
}

.tableStickyHead thead {
    position: sticky;
    top: 50px;
    z-index: 1;
}

.tableStickyHead th {
    background-color: white;
    box-shadow: inset 0 1px 0 var(--light-grey), inset 0 -1px 0 var(--light-grey);
    border-top: none !important;
    border-bottom: none !important;
}

td {
    overflow: hidden;
    vertical-align: middle !important;
}

    td a, td a:hover {
        display: block;
        margin: -100em;
        padding: 100em;
        text-decoration: none;
        text-decoration-line: none;
        color: black;
    }

.field-error-message {
    color: var(--d-red);
}

.red-color {
    color: var(--d-red) !important;
}

.red-color-opacity {
    color: var(--d-red) !important;
    opacity: 0.6;
}

td.compact .btn {
    width: 100%;
    height: 100%;
}

td.compact {
    padding: 0px !important;
}

.table th {
    padding: 0.5rem !important;
}

.table td {
    padding: 4px;
}

.tabBar {
    background-color: #FFFFFF;
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
}

    .tabBar ul {
        list-style-type: none;
        margin: 0px;
        padding: 0px;
        width: 100%;
        border-bottom: none;
        height: 31px;
    }

    .tabBar li {
        @extends .header-style;
        align-items: center;
        display: flex;
        font-size: xx-large;
        min-width: 128px;
    }

        .tabBar li a {
            height: 48px;
            /*border-right: 1px solid $brand-screen-background;*/
            border-bottom: none;
            overflow: hidden;
            padding: 0px 16px;
            align-items: center;
            justify-content: center;
            display: flex;
            text-decoration-line: none;
            text-decoration: none;
            font-size: 16px;
            color: #707070;
            font-weight: bold;
        }

            .tabBar li a.active {
                color: #000000;
                border-bottom: 4px solid var(--d-blue);
                border-top: 1px solid #F1F1F1;
                background-color: #F1F1F1;
            }

.tabBarContainer {
    padding: 16px 16px 0px;
    width: 100%;
}

@media (min-width: 768px) {
    .tabBar li {
        display: inline-block;
    }
}

.verticalContainer {
    display: flex;
    flex-flow: column nowrap;
    flex-grow: 1;
}

.undercaption {
    font-size: var(--font-size-body);
    color: #707070;
    padding-top: 16px;
}

.viewdrop {
    position: absolute;
    z-index: 10000;
    margin-top: 8px;
    background-clip: padding-box;
    box-shadow: 0 3px 12px rgba(27,31,35,.15);
}

.progress-ring__circle {
    transition: 0.35s stroke-dashoffset;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
}

.inputRowContainer {
    display: flex;
    width: 100%;
    column-gap: 16px;
}

    .inputRowContainer div {
        flex-grow: 1;
    }


@media print {
    body * {
        visibility: hidden;
    }

    .section-to-print, .section-to-print * {
        visibility: visible;
    }
}

.vertical-divider {
    width: 1px;
    height: 100%;
    background-color: var(--light-component-background);
}

.extended-vdivider {
    display: inline-block;
    height: 50px;
    width: 1px;
    margin-top: -25px;
    margin-bottom: -24px;
    background-color: var(--light-component-background);
}

.extended-vdivider-info-card {
    display: inline-block;
    /* height it 100% of parent, +16px to account for padding of parent container */
    height: Calc(100% + 16px);
    min-width: 1px;
    width: 1px;
    margin-top: -8px;
    background-color: var(--light-component-background);
}

.extend-hdivider {
    margin-left: -24px;
    margin-right: -24px;
    border-top: 2px solid var(--light-component-background);
    margin-top: -16px;
}

.search-card-container {
    width: 100%;
    padding: 4px 8px 4px 0px;
    display: flex;
    align-items: center;
}

.search-card-inner-container {
    display: flex;
    align-items: center;
    vertical-align: middle;
}

.search-card-search-icon-container {
    width: 56px !important;
    display: flex;
    align-items: center;
    vertical-align: middle;
    float: right;
}

.search-card-input {
    all: unset;
    width: 100%;
    display: inline-block;
    min-width: 100px;
    padding-left: 16px;
}

.solid-black-border {
    border-style: solid;
    border-width: 1px;
    border-color: black;
    border-radius: 4px;
}

/* This is a bit of a hack suggested by here https://supportcenter.devexpress.com/ticket/details/t844498/button-how-to-add-tooltip-to-disabled-button
   Basically forces the button to continue listening to mouse events when disabled so the tooltip will still display on hover */
.dx-btn-force-tooltip {
    pointer-events: auto !important;
}

.dx-icon-btn {
    color: var(--d-blue);
    background-color: var(--d-blue-faded);
    border-radius: var(--standard-border-radius);
    min-width: 32px;
    height: 32px;
    text-align: center;
    font-size: 12px;
    border: none;
}

/** overwrride style for the DxAccordion header to stop the background being grey **/
.dxbl-group > .dxbl-group-header {
    background-color: white !important;
}

.right-aligned-container {
    display: flex;
    margin-left: auto;
    margin-right: 0;
}

.right-aligned {
    margin-left: auto;
    margin-right: 0;
}

.right-aligned-button {
    bottom: 32px;
    right: 32px;
    margin: 0px 0px 0px 0px;
    min-width: 116px !important;
    left: 100%;
    float: right;
    height: 38px;
    font-family: Helvetica;
    font-weight: bold;
}

.right-aligned-button, sticky {
    position: sticky;
}

.left-aligned-button {
    bottom: 32px;
    right: 32px;
    margin: 0px 0px 0px 0px;
    min-width: 116px !important;
    float: left;
    height: 38px;
    font-family: Helvetica;
    font-weight: bold;
}

.left-aligned-button, sticky {
    position: sticky;
}

.mat-card-button {
    background-color: transparent;
    color: var(--light-brandcolour);
    width: 196px;
}

.matcard-padding-16 {
    padding: 16px;
}


.duf-mapping-headings-container {
    display: flex;
    flex-direction: row;
}

    .duf-mapping-headings-container span {
        display: flex;
        padding-left: 16px;
        font-size: var(--font-size-body);
        flex-grow: 1;
        flex-basis: 0;
    }

        .duf-mapping-headings-container span:first-child {
            flex-grow: 2;
        }

.duf-category-section-wrapper {
    padding: 16px 0px 0px 16px;
}

.category-selector-stripe-mdm {
    width: 4px;
    height: 100%;
    background-color: #5A4EC1; /*MDM Purple*/
    border-radius: 4px;
}

.category-selector-stripe-cad {
    width: 4px;
    height: 100%;
    background-color: #f38746; /*Deswik.CAD ORange*/
    border-radius: 4px;
}

.removable-selector-wrapper {
    display: flex;
    flex-flow: row;
    height: 56px;
}

.removable-selector {
    flex-grow: 1;
    border: none;
    padding-left: 12px;
    background-color: transparent;
}

.grid-removable-selector-wrapper {
    display: grid;
    grid-template-columns: 4px auto 1px 56px;
    height: var(--input-field-height);
}

.rule-card-wrapper {
    display: grid;
    grid-template-columns: 1fr 147px 1fr 147px;
    height: 56px;
    width: 100%;
}

.grid-removable-selector {
    border: none;
    padding-left: 12px;
    background-color: transparent;
    min-width: 0px;
}

.grid-width-controller {
    border: none;
    padding-left: 12px;
    background-color: transparent;
    min-width: 0px;
}

/*for grid item to stop pushing next items out of container
    we need to set min-width to 0px or other default value
    as grid auto width is content based.
*/
.grid-duf-mapping-lr-container {
    min-width: 0px;
    border-right: 1px solid var(--light-component-background);
}


.duf-mapping-lr-row {
    border-bottom: 1px solid var(--light-component-background);
    min-height: 56px;
    flex-flow: row nowrap;
    display: flex;
}

    .duf-mapping-lr-row:last-child {
        border: none;
    }

    .duf-mapping-lr-row select, .rule-mapping-row select {
        border: none;
        flex: 1 1 auto;
        height: 100%;
        padding: 16px;
    }

.duf-layer-delete {
    border: none;
    flex: 0 0 1;
    height: 100%;
    width: 56px;
    background-color: transparent;
    border-left: 1px solid var(--light-component-background);
}

.duf-mapping-delete {
    all: unset;
    border: none;
    margin: auto;
    height: 25%;
    width: 50%;
    background-color: white;
}

.duf-mapping-delete-text {
    font-size: 16px;
    text-transform: uppercase;
    text-align: right;
    color: black;
}

.duf-delete-button {
    all: unset;
    min-width: 56px;
    min-height: var(--input-field-height);
    text-align: center;
    background-color: transparent;
    color: var(--light-primary-text);
    --mdc-ripple-fg-size: 0px !important;
}

.duf-row-add-button {
    border: none;
    flex: 1 0 auto;
    column-gap: 12px;
    background-color: transparent;
    height: 56px;
    text-align: left;
    padding-left: 16px;
    display: flex;
    align-items: center;
    padding-right: 20px;
}

    .duf-row-add-button svg {
        color: var(--light-primary-text);
    }

    .duf-row-add-button p {
        text-transform: uppercase;
    }

.file-upload-dialog .mdc-dialog__surface {
    min-width: 512px;
    min-height: 384px;
    width: auto;
    height: auto;
    padding: 32px;
}

.file-upload-dialog-button-container {
    width: 100%;
}

.file-upload-dialog-overlay {
    width: auto;
    height: auto;
}

.map-component-form .mdc-dialog__surface {
    width: 512px;
}

.map-component-form .mdc-dialog__content {
    display: flex;
    flex-flow: column nowrap;
    row-gap: 16px;
}

.map-component-form .mdc-dialog__actions {
    padding: 0px 24px 8px 24px;
}

.duf-add-mapping-button, .rule-mapping-add-row-button {
    padding: 16px;
    border: none;
    background-color: transparent;
}

/* outer most container for the entire card */
.duf-mapping-row-container-editform {
    display: flex;
    column-gap: 16px;
    margin-bottom: 16px;
}

/* container for each mapping */
.duf-mapping-row {
    margin: 0px;
    padding: 0px;
    overflow: hidden;
    border: none;
}

.thin-grey-border {
    border: solid 1px var(--light-component-background);
    border-radius: var(--standard-border-radius);
}

.input.thin-grey-border:focus {
    border-color: red;
}

.left-only-border-radius {
    border-radius: var(--standard-border-radius) 0px 0px var(--standard-border-radius);
}

.right-only-border-radius {
    border-radius: 0px var(--standard-border-radius) var(--standard-border-radius) 0px;
}

.duf-mapping-comp-container input, .rule-mapping-row input {
    width: 100%;
    height: 100%;
    padding: 16px;
}

/* Clean up*/
.rule-mapping-form .mdc-dialog__surface {
    width: 1024px;
}

.rule-mapping-headings-container {
    display: grid;
    grid-template-columns: 1fr 147px 1fr 147px 56px;
}


    .rule-mapping-headings-container span {
        padding: 0px 16px;
    }

.rule-mapping-rows-container {
    display: flex;
    flex-flow: column nowrap;
    row-gap: 16px;
}

.rule-mapping-row, .rule-mapping-row .card {
    flex-flow: row nowrap;
    display: flex;
    padding: 0px;
    margin: 0px;
}


    .rule-mapping-row input, .rule-mapping-row select:not(:last-child) {
        border-right: 1px solid var(--light-component-background);
    }

.prefix-list {
    padding: 4px 8px;
    border: none;
    background-color: var(--light-component-background);
    border-radius: var(--standard-border-radius);
    margin-bottom: 16px;
}

.mapping-row-container {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
}

.deswik-account-overlay .mdc-dialog__surface {
    min-width: 512px;
}

.load-indicator {
    border: 4px solid white; /* Light grey */
    border-top: 4px solid var(--d-blue);
    border-radius: 50%;
    width: 32px;
    height: 32px;
    margin: 8px auto;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.dialog-title-margin {
    margin: 16px;
}

.Standardtable {
    width: 100%;
}

    .Standardtable thead {
        position: sticky;
        top: 3rem;
        background-color: white;
        z-index: 1;
    }

    .Standardtable th {
        border-bottom: 1px solid var(--light-component-background);
        border-collapse: collapse;
        padding-left: 8px;
    }

    .Standardtable td {
        border-bottom: 1px solid var(--light-component-background);
        border-collapse: collapse;
        padding-left: 8px;
    }

    .Standardtable tr {
        height: 56px;
    }

    .Standardtable *:not(thead) tr:hover {
        background-color: var(--light-component-background);
        cursor: pointer;
    }

    .Standardtable td:last-child {
        align-content: end;
    }

.Standardtablerow {
    font-weight: normal;
    font-size: var(--font-size-body);
}


.FloatingbuttonStyle {
    position: fixed;
    bottom: 16px;
    right: 16px;
    height: 38px;
    border-radius: var(--standard-border-radius) !important;
}

.Heading4Style {
    color: var(--light-primary-text);
    font-weight: bold;
    font-size: var(--font-size-body);
}

/*Cleanup required for Heading3Style to heading-3*/
.Heading3Style {
    color: var(--light-primary-text);
    font-weight: bold;
    font-size: 16px;
}

.heading-3 {
    color: var(--light-primary-text);
    font-weight: bold;
    font-size: 16px;
    margin: 0px
}

.Heading2Style, .heading-2 {
    color: var(--light-primary-text);
    font-weight: bold;
    font-size: 24px;
}

.Body1Style, .body-1 {
    color: var(--light-primary-text);
    font-weight: normal;
    font-size: 16px;
}

.Body2Style {
    color: var(--light-primary-text);
    font-weight: normal;
    font-size: var(--font-size-body);
}

.body-3 {
    color: var(--light-primary-text);
    font-weight: normal;
    font-size: 14px;
}

.Heading1Style {
    color: var(--light-primary-text);
    font-weight: bold;
    font-size: 16px;
}

.rule-mapping-headings-container span {
    color: var(--light-secondary-text);
    font-weight: normal;
    font-size: var(--font-size-body);
}

.Body2BoldStyle {
    color: var(--light-primary-text);
    font-weight: bold;
    font-size: var(--font-size-body);
}

.CaptionStyle {
    color: var(--light-primary-text);
    font-weight: normal;
    font-size: 12px;
}

.vertically-centered {
    margin-top: auto;
    margin-bottom: auto;
}

.LatestPDFBoxStyle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 1px solid var(--light-component-background);
    align-items: center;
    padding: 8px;
    border-radius: var(--standard-border-radius);
}

.IconbuttonStyle {
    padding: 0px;
}

.PdfbuttonStyle i {
    background-color: var(--primary-text);
}

.OutlinedrowContainer {
    width: 100%;
    padding: 0px;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    /*    margin-top:8px;*/
    /*flex-flow: row wrap;*/
}

.OutlinedMultipleColumnContainer {
    width: 100%;
    padding: 0px;
    display: flex;
    align-items: start;
    /*    margin-top:8px;*/
    /*flex-flow: row wrap;*/
}

.OutlinedsubsequenceItem {
    margin-left: 16px;
}

.OverlayTopHeadingStyle {
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;
}

    .OverlayTopHeadingStyle p {
        margin-right: 16px;
        margin-bottom: 4px;
    }

.SchedulePanelOuterBox {
    position: fixed;
    right: 0;
    top: 3.5rem;
    bottom: 0;
    width: 416px;
    z-index: 2;
    background-color: white
}

.SchedulePanelTopHeading {
    position: sticky;
    top: 0;
    z-index: 1000;
    padding: 16px;
    background-color: white;
}

.SchedulePanelBottomButtons {
    width: 100%;
    position: sticky;
    bottom: 0;
    z-index: 1000;
    padding: 16px;
    background-color: white;
}

.SchedulePanelInsideBox {
    margin: 16px;
    background-color: white;
    border: 1px solid var(--light-component-background);
    border-radius: var(--standard-border-radius);
    width: 384px;
    box-shadow: 0px 4px 8px #00000029;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: scroll;
}

    .SchedulePanelInsideBox::-webkit-scrollbar {
        display: none;
    }

.SchedulePanelGridLayout {
    display: grid;
    grid-template-columns: 24px 276px auto;
    height: 56px;
    column-gap: 16px;
    margin-bottom: 8px;
}

.SchedulePanelNumLine {
    display: grid;
    grid-template-columns: 24px;
    grid-template-rows: 1fr 24px 1fr;
    justify-items: center;
    row-gap: 2px
}

.SchedulePanelThinLine {
    background-color: var(--light-component-background);
    width: 1px;
}

.SchedulePanelNumberCircle {
    border-radius: 12px;
    width: 24px;
    display: flex;
    justify-content: center;
    text-align: center
}

.SchedulePanelLocationName {
    border: 1px solid var(--light-component-background);
    display: flex;
    flex-direction: row;
    border-radius: var(--standard-border-radius);
    box-shadow: 0px 4px 8px #00000029;
}

.SchedulePanelTimesAlignment {
    align-self: center;
    text-align: end;
    color: var(--light-primary-text);
    font-size: 16px;
}

.Numberboxdiv {
    height: 32px;
    width: 32px;
    border-radius: var(--standard-border-radius);
    display: flex;
    align-items: center;
    /* justify-items: center; */
    justify-content: center;
}

.mdc-dialog__actions {
    padding: 16px;
}

.dialog-content-margin {
    margin: 0 16px 16px 16px;
}

.mdc-dialog__actions {
    padding: 16px;
}

.mdc-notched-outline__notch {
    width: auto !important;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.MatDialogContentClass::-webkit-scrollbar {
    display: none;
}
/* Hide scrollbar for IE, Edge */
.MatDialogContentClass {
    -ms-overflow-style: none; /* IE and Edge */
}

.delete-mat-button {
    color: var(--d-red) !important;
}

.e-multiselect.e-input-group.e-control-wrapper.e-input-focus::before, .e-multiselect.e-input-group.e-control-wrapper.e-input-focus::after {
    border-top: 1px solid var(--light-brandcolour) !important;
}

.e-input-group:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left), .e-input-group.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap, .e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left), .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap, .e-float-input.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap, .e-float-input.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap {
    border-color: transparent !important;
}

.e-multi-select-wrapper {
    border: 1px solid rgb(0,0,0,0.28);
    padding: 16px;
    border-radius: var(--standard-border-radius);
}

    .e-multi-select-wrapper:hover {
        border: 1px solid black;
    }



.HoveredDiv {
    background: rgb(73,73,73,0.16);
    /*    border-style: solid none none none;
    border-color: rgb(73,73,73,0.16);
    border-width: 8px;*/
}

.HoveredDivEmpty {
    background-color: rgb(73,73,73,0.16);
    margin-left: 40px;
    margin-right: 20px;
    border-radius: 4px 4px;
    height: 46px;
}

.spinner {
    border: 8px solid #f3f3f3;
    border-radius: 50%;
    border-top: 8px solid #3498db;
    width: 80px;
    height: 80px;
    -webkit-animation: spin 2s linear infinite; /* Safari compatibility */
    animation: spin 2s linear infinite;
    margin: 0px auto;
}

.spinner-container {
    z-index: 12;
    display: flex;
    flex-flow: column;
    row-gap: 16px;
    height: 100%;
    align-content: center;
    justify-content: center;
}

    .spinner-container p {
        margin: 0px auto;
    }

.title-with-progress-circle-container {
    display: flex;
    align-items: center;
    column-gap: 10px;
    margin: 16px;
}

.matdialog-title {
    margin: 16px;
}

.matdialog-title-content {
    height: 80vh;
    max-height: 1028px;
    padding: 0px 16px;
    width: 50vw;
    max-width: 512px;
}

.overlay-content {
    height: 80vh;
    max-height: 1028px;
    padding: 0px 16px;
    width: 90vw;
    max-width: 512px;
}

.wide-overlay-content {
    height: 80vh;
    max-height: 1028px;
    padding: 0px 16px;
    width: 90vw;
    max-width: 1028px;
}

select:focus, button:focus {
    outline: none;
}


.interval-selector-container {
    width: 50%;
    padding-left: 16px;
    padding-bottom: 16px;
    display: flex;
    align-content: center;
    vertical-align: middle;
}

.interval-selector-left {
    color: var(--light-primary-text);
    background: var(--light-component-background);
    padding-top: 8px;
    padding-left: 16px;
    display: inline-block;
    width: 120px;
    border-right: none !important;
    vertical-align: middle;
    height: var(--input-field-height);
}

.interval-selector-middle {
    all: unset;
    color: var(--light-primary-text);
    padding-left: 16px;
    display: inline-block;
    width: 30%;
    border-right: none !important;
    height: calc(var(--input-field-height) - 2*var(--thin-border-thickness))
}

.interval-selector-right {
    color: var(--light-primary-text);
    background-color: none;
    padding-left: 12px;
    width: 50%;
    height: var(--input-field-height);
    border-right: 16px solid transparent !important;
    border: none;
}

.date-time-picker {
    height: var(--input-field-height);
    padding-left: 8px;
    color: var(--light-primary-text);
}

.input-field-height {
    height: var(--input-field-height)
}

.thin-border {
    border: var(--thin-border-thickness) solid var(--light-secondary-text);
}

.border-rounded-left {
    border-radius: 3px 0px 0px 3px;
}

.border-rounded-right {
    border-radius: 0px 3px 3px 0px;
}

.warning-component-container {
    display: block;
    background-color: var(--d-yellow-faded);
    width: fit-content;
    padding: 8px;
    border-radius: var(--standard-border-radius);
}

.left-floating-centered {
    margin: auto;
    margin-right: 8px;
    float: left;
}

/*progress circle with a text inside cite - styles*/

/*.MatProgressCircle {
    position: absolute;
    left: 0;
    top: 0;
}

.BasecircleSvg {
    position: absolute;
    left: 0;
    top: 0;
}

.TextInMatProgressCircle {
    position: absolute;
    left: 18px;
    top: 12px;
    z-index: 2;
    color: var(--light-brandcolour);
    font-weight: 700;
    font-size: var(--font-size-heading);
}

.DialogStepTitle {
    display: grid;
    grid-template-columns: 48px 1fr;
    vertical-align: central;
    height: 48px;
    margin-bottom: 16px;
    align-self: center;
    column-gap: 12px;
}


.DialogTitleName {
    display: flex;
    vertical-align: central;
    margin-bottom: 16px;
    align-self: center;
}

.MatDialogContentClass {
    min-width: 512px;
    padding: 16px;
    height: 60vh;
    max-height: 512px;
}*/
