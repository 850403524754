.info-row-wrapper {
	display: grid;
	grid-template-columns: auto 1fr;
	column-gap: 12px;
	height: 48px;
	margin-bottom: 8px;
}

.info-line {
	grid-row: 1;
	grid-column: 1;
	margin: auto;
}

.info-icon-mask {
	grid-row: 1;
	grid-column: 1;
	width: 24px;
	height: 24px;
	background-color: var(--panel-level-1);
	margin: auto;
}

.info-icon {
	grid-row: 1;
	grid-column: 1;
	font-size: 14px;
	margin: auto;
	color: var(--light-primary-text);
}

.info-card-wrapper {
	grid-row: 1;
	grid-column: 2;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 0px 0px 0px 8px;
}
