.mad-date-picker-container {
    display: inline-flex;
    align-items: center;
    position: relative;
}

.mad-date-picker-arrows-container {
    display: inline-flex;
    flex-direction: column;
    position: absolute;
    right: 28px;
}

.mad-date-picker-input-date-label {
    position: absolute;
    margin-top: 14px;
    margin-left: 8px;
    background: white;
    padding-left: 8px;
    padding-right: 24px;
    width: auto !important;
}

.mad-date-picker-input-date {
    width:230px;
    height:56px;
    padding-left: 12px;
}

input[type=date]:focus {
    outline: none !important;
    border: 2px solid #3D9BEA;
}

input[type=date] {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    border: 1px solid #858585;
    color: var(--light-primary-text);
}

input[type=date]::-webkit-calendar-picker-indicator {
    width: 28px;
    height: 28px;
    opacity: 0.7;
}

.mad-date-picker-arrow {
    background: transparent;
    height: 16px;
    border: transparent;
    font-size: 10px;
    display: flex;
    color: rgba(0, 0, 0, 0.42);
}

    .mad-date-picker-arrow:active, .mad-date-picker-arrow:focus {
        outline: none;
    }

.mat-text-field-with-actions-container .mat-text-field-actions-container {
    right: 0px !important;
    top: unset;
    background: #e8e8e8cc;
    height: 100% !important;
    width: 54px !important;
    border: 1px solid #a8a8a8;
    border-radius: 0px 3px 3px 0px;
}

    .mat-text-field-with-actions-container .mat-text-field-actions-container:hover {
        background: #d0d0d0cc;
    }

.mat-text-field-actions-container button {
    --mdc-ripple-fg-size: 0px !important;
}
