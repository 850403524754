.survey-plan-list-page-section-container {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 3.5rem - 17.6px); /*height = screen-height - top-row-height - footer-padding-top*/
}

.survey-plan-list-page-search-container {
    display: flex;
    margin: 30px 0px 5px 0px;
}

.survey-plan-list-page-search-field {
    display: inline-flex;
    flex-grow: 1;
    height: 48px !important;
    border-top-right-radius: 0px !important;
}

.survey-plan-list-page-search-button {
    background: var(--d-blue);
    border: none;
    border-radius: 0px 4px 4px 0px;
    padding: 5px;
    height: 48px;
    min-width: 48px;
    font-size: 20px;
    color: #ffffff;
}

    .survey-plan-list-page-search-button:active, .survey-plan-list-page-search-button:focus {
        outline: none;
    }


.survey-plan-list-page-filters-container {
    /*TODO*/
}

.survey-plan-list-page-add-button-container {
    text-align: center;
}

.survey-plan-list-page-add-button {
    width: 100%;
    font-size: 16px !important;
    height: 48px !important;
    justify-content: start !important;
}

.survey-plan-list-page-list-container {
    overflow: auto;
    flex-grow: 1;
}

.survey-plan-list-page-cards-container {
    display: flex;
    justify-content: space-around;
}

.survey-plan-list-page-card {
    width: 220px;
    display: flex;
    flex-flow: column;
    text-align: center;
    justify-content: space-between;
}

.survey-plan-list-page-sub-header {
    margin: 0px !important;
    padding: 12px 16px;
    background-color: #d5d5d5;
}

.survey-plan-list-page-task-container {
    display: flex;
    justify-content: space-between !important;
}

.survey-plan-list-page-task {
    display: flex;
    align-items: center;
    height: 100%;
}

.survey-plan-list-page-task-marker {
    width: 5px;
    height: 90%;
    margin: 0px 5px 0px 0px;
}

/*  This text gets generated when the MatListGroup is created,
    which creates a whitespace at the bottom of the page.
    The css is for hiding the text.
*/
#gauge-measuretext {
    font-size: 0px !important;
}