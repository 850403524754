.cut-list-page-add-button {
    margin-left: 30px;
}

.cut-list-page-drow-down {
    min-width: 177px;
}

.cut-list-page-table {
    margin-bottom: 8px;
    border-collapse: separate;
    border-spacing: 0 8px;
}

.cut-list-page-table tr {
    height: auto;
}

.cut-list-page-table td {
    padding-right: 7px;
    padding-top: 8px;
    vertical-align: top !important;
}

#cut-list-page-table-checkbox-container {
    width: 120px;
    vertical-align: middle;
}
